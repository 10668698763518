




















import { mapGetters } from "vuex";
import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

export default defineComponent({
  name: "Services",
  computed: {
    ...mapGetters("monitoringBussinessProcessesView", ["services", "isServicesLoading"]),
    resultServices(): { name: string; status: string; version: string }[] {
      return (this.services || []).concat({
        name: "FRONT",
        status: "UP",
        version: environmentVariables.VUE_APP_CLIENT_VERSION,
      });
    },
  },
});
