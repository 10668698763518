

















































import Filters from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/components/Filters.vue";
import TimeGraph from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/components/TimeGraph.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Panel from "@monorepo/uikit/src/components/common/Panel.vue";
import { defineComponent } from "@vue/composition-api";
import { breadcrumbs } from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/constants/breadcrumbs";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/viewTitle";
import Services from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/components/Services.vue";
import { module as monitoringBussinessProcessesView } from "@monorepo/monitoring/src/store/modules/monitoringBussinessProcessesView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import useGetTableLibs from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/composables/useGetTableLibs";
import { mapGetters, Module } from "vuex";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  name: "MonitoringBussinessProcceses",
  components: {
    Filters,
    TimeGraph,
    ScrollPanel,
    Breadcrumbs,
    Panel,
    Services,
  },
  data() {
    return {
      openedPanels: [],
      sectionName: "monitoringBussinessProcessesView",
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("monitoringBussinessProcessesView", ["isTableLoading"]),
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "monitoringBussinessProcessesView", monitoringBussinessProcessesView as Module<unknown, unknown>);
    useOpenHistoryMode(root, "monitoringBussinessProcessesView");
    useGetTableLibs(root);
  },
});
