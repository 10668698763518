



















import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import BubbleChart from "@monorepo/uikit/src/components/charts/BubbleChart.vue";
import TooltipGraph from "./TooltipGraph.vue";
import { mapActions, mapGetters } from "vuex";
import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/fields";
import { IGraphElement } from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/types/graphElement";

export default defineComponent({
  name: "TimeGraph",
  components: {
    ExportButton,
    BubbleChart,
    TooltipGraph,
  },
  data() {
    return {
      isShowTooltip: false,
      tooltipPositions: [] as number[],
      openedIndex: null as number | null,
    };
  },
  computed: {
    ...mapGetters("monitoringBussinessProcessesView", ["graphFilters", "graphData"]),
    isShowGraph(): boolean {
      return !!(
        (this.graphFilters as { [key: string]: string })?.[fields.PERIOD_FIlTER_FROM] &&
        (this.graphFilters as { [key: string]: string })?.[fields.PERIOD_FIlTER_TO]
      );
    },
    options(): { [key: string]: unknown } {
      const minDate = (this.graphFilters as { [key: string]: string })?.[fields.PERIOD_FIlTER_FROM] || null;
      const maxDate = (this.graphFilters as { [key: string]: string })?.[fields.PERIOD_FIlTER_TO] || null;
      const resultMinDate = minDate
        ? moment(minDate, "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD HH:mm")
        : moment().startOf("day").format("YYYY-MM-DD HH:mm");
      const resultMaxDate = maxDate
        ? moment(maxDate, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD HH:mm")
        : moment().endOf("day").format("YYYY-MM-DD HH:mm");

      return {
        graphKey: uuid(),
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: "Golos",
                fontSize: 10,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              gridLines: {
                offsetGridLines: true,
              },
              time: {
                parser: "YYYY-MM-DD HH:mm",
                unit: "day",
                stepSize: 1,
                displayFormats: {
                  day: "DD.MM",
                },
                tooltipFormat: "YYYY-MM-DD",
              },
              ticks: {
                min: resultMinDate,
                max: resultMaxDate,
                fontFamily: "Golos",
                fontSize: 10,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        events: ["click"],
        tooltips: {
          enabled: false,
          width: 400,
          height: 300,
          custom: (tooltip: { dataPoints?: { index: number }[]; afterBody: boolean; x: number; y: number }) => {
            const openedIndex = tooltip.dataPoints?.[0]?.index ?? null;
            (this as { toggleTooltip: (isShowTooltip: boolean, tooltipPositions: number[], openedIndex: number | null) => void }).toggleTooltip(
              tooltip.afterBody,
              tooltip.afterBody ? [tooltip.x, tooltip.y] : [],
              openedIndex
            );
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
      };
    },
    datacollection() {
      const minDate = (this.graphFilters as { [key: string]: string })?.[fields.PERIOD_FIlTER_FROM] || null;
      const maxDate = (this.graphFilters as { [key: string]: string })?.[fields.PERIOD_FIlTER_TO] || null;
      const momentLineElement = minDate ? moment(minDate, "YYYY-MM-DD").add(-1, "day") : moment().add(-1, "day");
      const momentMaxLineElement = maxDate ? moment(maxDate, "YYYY-MM-DD").add(1, "day") : moment().add(1, "day");
      const linePosition = ((this.graphData as IGraphElement[]) || []).reduce((res: number, item: IGraphElement) => {
        return res + (item.timeDifference || 0);
      }, 0);
      const dataLength = ((this.graphData as IGraphElement[]) || []).length;

      return {
        labels: ["Data"],
        datasets: [
          {
            type: "line",
            label: "Line Dataset",
            fill: false,
            borderColor: "#D34039",
            borderWidth: 1,
            data: [
              {
                x: momentLineElement.format("YYYY-MM-DD"),
                y: dataLength ? linePosition / dataLength : 0,
              },
              {
                x: momentMaxLineElement.format("YYYY-MM-DD"),
                y: dataLength ? linePosition / dataLength : 0,
              },
            ],
          },
          {
            label: "Data One",
            backgroundColor: "#2462D1",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: (this.graphData as IGraphElement[]).map((item: IGraphElement) => {
              return {
                x: moment(item.creationTransportContainerDate).format("YYYY-MM-DD HH:mm"),
                y: item.timeDifference,
                r: 6,
                element: item,
              };
            }),
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions("monitoringBussinessProcessesView", ["getExportData"]),
    toggleTooltip(isShowTooltip: boolean, tooltipPositions: number[] = [], openedIndex: number | null) {
      this.tooltipPositions = tooltipPositions;
      this.isShowTooltip = isShowTooltip;
      this.openedIndex = openedIndex;
    },
  },
});
