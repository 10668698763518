















import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { IGraphElement } from "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/types/graphElement";
import { convertStatusesToTitle } from "@monorepo/utils/src/utils/convertStatusesToTitle";
import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";

export default defineComponent({
  name: "TooltipGraph",
  props: {
    tooltipPositions: {
      type: Array,
      default() {
        return [];
      },
    },
    openedIndex: {
      type: [Number],
      default() {
        return 0;
      },
    },
  },
  computed: {
    ...mapGetters("monitoringBussinessProcessesView", ["graphData"]),
    item(): IGraphElement {
      return typeof this.openedIndex === "number" ? this.graphData[this.openedIndex] : {};
    },
    status(): string {
      return this.item?.status ? convertStatusesToTitle(this.item?.status as TK_STATUS) : "";
    },
  },
  methods: {
    redirectToPage() {
      this.$router.push({ path: "/inventory/tk", query: { medoGuid: this.item.medo_guid.toString(), isOpenModal: "1" } });
    },
  },
});
