





































import moment from "moment";
import { defineComponent } from "@vue/composition-api";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/filtersElements";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export default defineComponent({
  components: {
    DatePicker,
    FindButton,
    ClearFilters,
  },
  data() {
    return {
      currentFilters: setCurrentFilters(),
      fields,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("monitoringBussinessProcessesView", ["isTableLoading"]),
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
    filter(): (id: string) => { value: string; title: string } {
      return (id) => {
        return filtersElements.find((item) => item.value === id) ?? { value: "", title: "" };
      };
    },
  },
  methods: {
    ...mapMutations("monitoringBussinessProcessesView", ["setGraphFilters"]),
    ...mapActions("monitoringBussinessProcessesView", ["getGraphData"]),
    clearFilters() {
      this.currentFilters = setCurrentFilters();
      this.setGraphFilters({ ...this.currentFilters });
      this.getGraphData();
    },
    refresh() {
      if (!this.currentFilters[fields.PERIOD_FIlTER_TO] || !this.currentFilters[fields.PERIOD_FIlTER_FROM]) {
        showNotification("Введите период");
        return;
      }
      this.setGraphFilters({ ...this.currentFilters });
      this.getGraphData();
    },
  },
  mounted() {
    this.refresh();
  },
});
